import React, { useEffect, useState } from 'react';
import Wrapper from '../../components/Wrapper';
import './style.css';
import Task from '../../components/Task';
import { MainContainer, LeftSide, RightSide, SuggestionsContainer, SuggestionWindow, SuggestionText, ProcessedCommentContainer, ProcessedCommentWrapper, SideHeadline, SideContentWrapper } from './index.style';
import TaskTitle from '../../components/TaskTitle';
import CustomButton from '../../components/CustomButton';
import CustomTextArea from '../../components/CustomTextArea';
import CommentHighlight from '../../components/CommentHighlight';
import * as api from '../../utils/api';
import Container from '../../components/Container';
import Navbar from '../../components/Navbar';
import CustomInput from '../../components/CustomInput';
import CustomSelect from '../../components/CustomSelect';
import {useStepsContext} from '../../contexts/StepsContext';
import TranscriptEditor from '../../components/TranscriptEditor';
import TranscriptDisplay from '../../components/TranscriptDisplay';
import VoiceMemoDisplay from '../../components/VoiceMemoDisplay';
import AnnotationRecordButton from '../../components/AnnotationRecordButton';

const TRANSCRIPT_LANGUAGES = [
    { value: 'nl', text: 'Dutch'},
    { value: 'en', text: 'English' },
    { value: 'fr', text: 'French'}
]

function Main() {
    const { updateStepData, getStepData } = useStepsContext();
    const [selectedLanguage, setSelectedLanguage] = useState('nl');
    // const [menuItems, setMenuItems] = useState(MENU_ITEMS);
    const [selectedStep, setSelectedStep] = useState();
    const [selectedHighlight, setSelectedHighlight] = useState(null);
    const [hoveredHighlight, setHoveredHighlight] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    // can save multiple AudioURLs to audioRecordings, an array with both the recordings & timestamps
    const [audioRecordings, setAudioRecordings] = useState([]);
    const [isTranscribing, setIsTranscribing] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    // Parts are all parts of the text, including the annotated ones
    // Annotations are only the annotated parts
    const { transcripts = [], processedComments, parts, annotations } = getStepData(selectedStep);

    const [isCopied, setIsCopied] = useState(false);
    const [editingTranscript, setEditingTranscript] = useState(null);

    const [componentOptions, setComponentOptions] = useState([]);
    const [componentMenuOptions, setComponentMenuOptions] = useState([]);

    const [selectedComponent, setSelectedComponent] = useState('oven');
    const [selectedMenu, setSelectedMenu] = useState([]);
    const [recordingStartTime, setRecordingStartTime] = useState(null);

    const [isSpinning, setIsSpinning] = useState(false);



    const getTextToCopy = () => {
        return parts.map(part => part.text).join('');
    };

    const handleCopy = async () => {
        await navigator.clipboard.writeText(getTextToCopy());
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
    };

    // useEffect(() => {
    //     // Update menu items when the selected component changes
    //     if (selectedComponent === 'steam oven') {
    //         setMenuItems(STEAM_OVEN_MENU_ITEMS);
    //     } else {
    //         setMenuItems(MENU_ITEMS);
    //     }

    //     // Make sure the selected step is valid for the new menu items
    //     const newMenuItems = selectedComponent === 'steam oven' ? STEAM_OVEN_MENU_ITEMS : MENU_ITEMS;
    //     if (!newMenuItems.some(item => item.name === selectedStep)) {
    //         setSelectedStep(newMenuItems[0].name);
    //     }
    // }, [selectedComponent, selectedStep]);


    useEffect(() => {
        const fetchComponentData = async () => {
          const data = await api.getComponentData(); // Call the actual data-fetching function

                const transformedOptions = transformComponentData(data.component_data);
                setComponentOptions(transformedOptions);

                const transformedMenuItems = transformComponentStepsData(data.component_data);
                console.log("transformedMenuItems", transformedMenuItems);
                setComponentMenuOptions(transformedMenuItems);
                console.log("componentMenuOptions in function 1", componentMenuOptions);

                // Set initial component and step only if we have valid data
                if (transformedOptions.length > 0 && transformedOptions[0].options.length > 0) {
                    const initialComponent = transformedOptions[0].options[0].value;
                    setSelectedComponent(initialComponent);

                    const initialMenu = transformedMenuItems.find(item => item.component === initialComponent);
                    if (initialMenu && initialMenu.steps.length > 0) {
                        setSelectedStep(initialMenu.steps[0]);
                    }
                }
            }

        fetchComponentData(); // Call the async function once
      }, []); // Empty dependency array to run only on initial render




    const transformComponentData = (componentData) => {
        const categories = {};

        const capitalizeText = (text) => {
            return text
              .split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');
          };


        componentData.component_name.forEach((name, index) => {
            const category = componentData.component_category[index];
            if (!categories[category]) {
              categories[category] = {
                category,
                options: []
              };
            }
            categories[category].options.push({ value: name, text: capitalizeText(name) });
          });
          // Convert the categories object into an array for the final format
          return Object.values(categories);

    };

    useEffect(() => {
        // Find the menu items corresponding to the selected component
        const foundMenu = componentMenuOptions.find(item => item.component === selectedComponent);


        if (foundMenu) {
          setSelectedMenu(foundMenu.steps);
          // Set the first step of the selected component if no step is selected
            if (!selectedStep || !foundMenu.steps.includes(selectedStep)) {
                setSelectedStep(foundMenu.steps[0]);
            }
        }

      }, [selectedComponent, selectedStep, componentMenuOptions]);  // Add componentMenuOptions to dependency array


      useEffect(() => {

      }, [componentMenuOptions]);  // This will log the updated value of componentMenuOptions after it changes


    const transformComponentStepsData = (componentData) => {
        const capitalizeWords = (step) => {
            return step.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
          };

          const menuItems = componentData.component_name.map((name, index) => {
            const steps = componentData.component_steps[index]
              .split(";") // Split steps by semicolon
              .map(step => step.trim()) // Trim whitespace
              .map(capitalizeWords); // Capitalize the first letter of each word in each step

            return {
              component: name,
              steps: steps
            };
          });

          return menuItems;
        };


    const handleDeleteAllNotes = () => {
        // Clear data for all steps
        selectedMenu.forEach(step => {
            updateStepData(step, {
                transcripts: [],
                processedComments: '',
                parts: [],
                annotations: []
            });
        });
    };


    const handleComponentChange = (e) => {
        const newComponent = e.target.value;
        setSelectedComponent(newComponent);

        const selectedMenu = componentMenuOptions.find(item => item.component === newComponent);
        if (selectedMenu) {
            // Reset selected step to the first step of the selected component
            setSelectedStep(selectedMenu.steps[0]);

            // Clear data for all steps of the new component
            selectedMenu.steps.forEach(step => {
              updateStepData(step, {
                transcripts: [],
                processedComments: '',
                parts: [],
                annotations: []
              });
            });
          } else {
            // Handle the case when the component is not found (optional)
            console.error('Component not found:', newComponent);
          }


        // // reset selected step to the first item of the new menu
        // const newMenuItems = newComponent === 'steam oven' ? STEAM_OVEN_MENU_ITEMS : MENU_ITEMS;
        // setSelectedStep(newMenuItems[0].name);

        // // clear data for all steps when the component changes
        // newMenuItems.forEach(item => {
        //     updateStepData(item.name, {
        //         transcripts: [],
        //         processedComments: '',
        //         parts: [],
        //         annotations: []
        //     });
        // });
    };

    const handleAreaClick = (event) => {
        // create a new note if the user clicks directly on the container
        if (event.target === event.currentTarget) {
            handleNewNote();
        }
    };

    const handleEditTranscript = (index) => {
        setEditingTranscript({...transcripts[index]});
    };

    // moved difference in saving edited notes and saving new notes differently up to the function
    const handleSaveTranscript = (editedTranscript) => {
        const existingIndex = transcripts.findIndex(t => t.timestamp === editedTranscript.timestamp);
        let updatedTranscripts;

        if (existingIndex !== -1) {
            // edit existing transcript
            updatedTranscripts = transcripts.map((t, index) =>
                index === existingIndex ? editedTranscript : t
            );
        } else {
            // add new one
            updatedTranscripts = [...transcripts, editedTranscript];
        }

        updateStepData(selectedStep, { transcripts: updatedTranscripts });
    };

    const handleDeleteTranscript = (index) => {
        const updatedTranscripts = transcripts.filter((_, i) => i !== index);
        updateStepData(selectedStep, { transcripts: updatedTranscripts });
    };
    
    const handleNewNote = () => {
        const newTranscript = {
            timestamp: new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', second:'2-digit'}),
            text: '',
            carriedOver: false
        };
        setEditingTranscript(newTranscript);
    };

    // recording from the annotations button is set as an answer to the 'Other, please specify' field
    const handleAnnotationRecording = (index, recordedText) => {
        let newParts = [...parts];
        newParts[index].annotationAnswer = recordedText;
        newParts[index].predefinedAnswer = 'Other, please specify!';
        updateStepData(selectedStep, { parts: newParts });
    };


    useEffect(() => {
        // Check token, redirect to login if doesn't exist
        if (!localStorage.getItem('jwtToken')) {
            window.location.href = '/login';
        }

        // Check if the user is logged in
        api.validateToken().then(r => {
            if (!r)
                window.location.href = '/login';
        }).catch(e => {});

         // Ask for microphone access
         if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
                const mimeType = 'audio/webm;codecs=opus';
                const newMediaRecorder = new MediaRecorder(stream, {mimeType});
                setMediaRecorder(newMediaRecorder);

                newMediaRecorder.ondataavailable = e => {
                    const audioData = e.data;
                    const audioSrc = URL.createObjectURL(audioData);
                    const timestamp = new Date().toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', second: '2-digit'});
                    setAudioRecordings(prevRecordings => [...prevRecordings, { url: audioSrc, timestamp }]);
                };
            });
        }
    }, []);

    useEffect(() => {
        // transcibe most recent recording added to the array
        const latestRecording = audioRecordings[audioRecordings.length - 1];
        if (latestRecording) {
            console.log('New audio recording added:', latestRecording);
            transcribeAudio(latestRecording);
        }
    }, [audioRecordings]);

    const startRecording = () => {
        if (mediaRecorder) {
            if (!isRecording) {
                setIsRecording(true);
                setRecordingStartTime(Date.now());
                mediaRecorder.start();
                console.log(mediaRecorder.state);
                console.log("recorder started");
            } else {
                setIsRecording(false);
                mediaRecorder.stop();
                console.log(mediaRecorder.state);
                console.log("recorder stopped");
            }
        }
    };

    /*
        - This function parses the annotated parts of the text and prepares them for rendering
        - It returns an array of (all) parts of the text and an array of annotations (annotated parts of the text)
    */
    const parseText = (apiText) => {
        // A regular expression to find the annotated parts
        const regex = /\{"text": "(.*?)", "question": "(.*?)", "header": "(.*?)", "options": \[(.*?)\]\}/g;
        let parts = [];
        let annotations = [];
        let lastIndex = 0;
        
        apiText.replace(regex, (match, text, question, header, options, index) => {
            // Add the text before the annotation
            if (index > lastIndex) {
                parts.push({ text: apiText.slice(lastIndex, index) });
            }
    
            const annotationAnswer = '';
            const predefinedAnswer = '';
            options = JSON.parse("[" + options + "]");
        
            // Add the annotated text
            parts.push({ text, question, header, options, isAnnotated: true, index, annotationAnswer, predefinedAnswer});
            annotations.push({ text, question, header, options, index, annotationAnswer, predefinedAnswer });
        
            // Update lastIndex to the end of the current match
            lastIndex = index + match.length;
        });
        
        // Add any remaining text after the last match
        if (lastIndex < apiText.length) {
            parts.push({ text: apiText.slice(lastIndex) });
        }
        
        return { parts, annotations };
    }

    /*
        - This function is called when the user clicks on the "Transcribe" button
        - It sends the audio file to the backend
        - It sends the selected language and the selected component to the backend
        - The backend transcribes the audio and returns the unprocessed comments
        - The transcription is displayed on the left side of the UI
    */
    const transcribeAudio = (recording) => {
        console.log('transcribing...', recording.url);
        setIsTranscribing(true);
        api.transcribeAudio(recording.url, selectedLanguage, selectedComponent).then(r => {
            const newTranscript = {
                timestamp: recording.timestamp,
                text: r['unprocessed_comments'],
                carriedOver: false
            };
            updateStepData(selectedStep, {
                transcripts: [...transcripts, newTranscript]
            });
            setIsTranscribing(false);
        });
    };

    /*
        - This function is called when the user clicks on the "Process comments" button
        - It sends the unprocessed comments to the backend
        - The backend processes the comments and returns the processed comments with annotations
        - Annotations are parsed and saved in the state to display them in the UI
    */
        const processComments = () => {
            setIsProcessing(true);

            const unprocessedComments = transcripts.map(t => `[${t.timestamp}] ${t.text}`).join('\n\n');
            api.processComments(unprocessedComments, selectedStep, selectedComponent).then(r => {



                Object.entries(r).forEach(([step, comment]) => {
                    if (step === selectedStep) {
                        // Update processedComments for the current step (right container)
                        const parsedText = parseText(comment);
                        updateStepData(step, {
                            processedComments: comment,
                            parts: parsedText.parts,
                            annotations: parsedText.annotations
                        });
                    } else {
                        // Create a new note for other steps
                        const newTranscript = {
                            timestamp: new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', second:'2-digit'}),
                            text: comment,
                            carriedOver: true
                        };
                        console.log("transcript that's supposed to go to the step: ", newTranscript);
                        
                        // Use the existing functions to save the new note
                        const stepData = getStepData(step);
                        updateStepData(step, {
                            ...stepData,
                            transcripts: [...(stepData.transcripts || []), newTranscript]
                        });
                    }
                });
        
                setIsProcessing(false);
            });
        };

    /*
        - This function is called when the user clicks on the "Confirm answers" button
        - It sends the annotated answers to the backend in specific format
        - The backend processes the answers and returns the updated comments
    */
    const sendAnnotationAnswers = () => {
        setIsProcessing(true);

        let notesWithFeedback = '';
        parts.forEach(part => {
            if(part.isAnnotated) {
                // Add JSON with answer as string
                notesWithFeedback += `{"text": "${part.text}", "question": "${part.question}", "answer": "${part.annotationAnswer}"}`;
            } else {
                notesWithFeedback += part.text;
            }
        });
        
        api.processAnnotationAnswers(notesWithFeedback, selectedStep, selectedComponent).then(r => {
            const parsedText = parseText(r['reviewed_notes']);
            updateStepData(selectedStep, {
                parts: parsedText.parts,
                annotations: parsedText.annotations
            });
            setIsProcessing(false);
        });
    }
    
    return (
        <Container>
            <Navbar steps={selectedMenu} selectedStep={selectedStep} onStepChange={newStep => setSelectedStep(newStep)} onSignOut={() => {
                    // Remove token and redirect to login
                    localStorage.removeItem('jwtToken');
                    window.location.href = '/login';
            }}/>

            
            

            <Wrapper style={{ height: '100%' }}>
                <MainContainer>
                    {/* Left side of the container - written/transcripted notes */}
                    <LeftSide>
                        <SideHeadline>Your notes - {selectedStep}</SideHeadline>
                        <SideContentWrapper>
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: 15, marginBottom: 15 }}>
                                {/* Language select */}
                                <CustomSelect
                                    options={componentOptions}
                                    onChange={handleComponentChange}
                                    value={selectedComponent}
                                />

                                <CustomSelect style={{marginLeft: 10}}
                                    options={TRANSCRIPT_LANGUAGES}
                                    onChange={e => setSelectedLanguage(e.target.value)}
                                    value={selectedLanguage}
                                />

                                <CustomButton style={{marginLeft: 10}} disabled={isTranscribing} text={isRecording ? 'Stop recording' : 'Record notes'} iconName={isRecording ? 'FaRegStopCircle' : 'FaMicrophone'} isRecording={isRecording} onClick={startRecording}/>
                            </div>
                            <div style={{ width: '100%', flexGrow: 1, marginBottom: 15, border: '1px solid #ced4da', borderRadius: '4px', position: 'relative', display: 'flex', flexDirection: 'column', height: 'calc(100vh - 380px)'}}>
                                <div style={{ overflowY: 'auto', padding: '10px', flexGrow: 1, marginBottom: '40px' }} onClick={handleAreaClick} >
                                    {transcripts.map((transcript, index) => (
                                        <TranscriptDisplay
                                            key={index}
                                            transcript={transcript}
                                            onEdit={() => handleEditTranscript(index)}
                                            onDelete={() => handleDeleteTranscript(index)}
                                        />
                                    ))}
                                    {(isRecording || isTranscribing) && recordingStartTime && (
                                        <VoiceMemoDisplay startTime={recordingStartTime} isRecording={isRecording} />
                                    )}
                                </div>
                                <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, padding: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                                    <CustomButton
                                        text="New Note"
                                        iconName="FaPlus"
                                        onClick={handleNewNote}
                                        style={{ width: '20%', padding: '5px 10px', fontSize: '0.8em'}}
                                    />
                                </div>
                            </div>
                        </SideContentWrapper>
                    </LeftSide>

                    {/* Right side of the container - SAP input */}
                    <RightSide>
                        <SideHeadline>SAP input - {selectedStep} </SideHeadline>
                        <SideContentWrapper>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 15, marginBottom: 15}}>
                                <CustomButton style={{marginLeft: 'auto', marginRight: 10}} disabled={isProcessing} text='Process comments' iconName='FaSync' spinningIcon={isProcessing} onClick={() => { processComments(); }} />
                                <CustomButton disabled={annotations.length == 0 || isProcessing} text='Confirm answers' iconName='FaCheck' onClick={() => { sendAnnotationAnswers(); }} />
                            </div>

                            <ProcessedCommentContainer style={{ marginBottom: 15, flexGrow: 1, whiteSpace: 'pre-wrap' }}>
                                {/* The processed comments are rendered here, including their highlighted clickable parts */}
                                <ProcessedCommentWrapper>
                                    {parts.map((part, index) => {
                                        if(part.isAnnotated) {
                                         return <CommentHighlight
                                                key={'highlight_' + index}
                                            selected={part.index === selectedHighlight || part.index === hoveredHighlight}
                                                onMouseEnter={() => { setHoveredHighlight(part.index); }}
                                                onMouseLeave={() => { setHoveredHighlight(null); }}
                                        >
                                            {part.text}
                                        </CommentHighlight>
                                        } else {
                                            return <span key={index}>{part.text}</span>
                                        }
                                        })}
                                </ProcessedCommentWrapper>

            <SuggestionsContainer>
                {
                    // The annotations parts are rendered here, including the input fields for the answers
                    parts.map((part, index) => {
                        if(!part.isAnnotated) return null;

                        // Initially only the predefined answer is rendered
                        let inputComponents = [
                            <CustomSelect
                                key={'select_' + index}
                                options={part.options.map(option => ({ value: option, text: option }))}
                                onChange={e => {
                                    let newParts = [...parts];
                                    newParts[index].predefinedAnswer = e.target.value;
                                    if (e.target.value.toLowerCase().indexOf('other') >= 0 || e.target.value.toLowerCase() === 'provide removal reason')
                                        newParts[index].annotationAnswer = '';
                                    else
                                        newParts[index].annotationAnswer = e.target.value;
                                    updateStepData(selectedStep, { parts: newParts });
                                }}
                                value={part.annotationAnswer}
                                outerStyle={{ width: '100%', height: 30}}
                                style={{ width: '100%', height: 30, padding: 0, paddingLeft: 10, paddingRight: 25}}
                            />,
                            <AnnotationRecordButton
                                key={'record_' + index}
                                disabled={isTranscribing}
                                onRecordingComplete={(text) => handleAnnotationRecording(index, text)}
                                selectedLanguage={selectedLanguage}
                                selectedComponent={selectedComponent}
                            />
                        ];

                        if(part.predefinedAnswer.toLowerCase().indexOf('other') >= 0 || part.predefinedAnswer.toLowerCase() === 'provide removal reason') {
                            inputComponents.push(
                                <CustomInput
                                    key={'input_' + index}
                                    value={part.annotationAnswer}
                                    onChange={e => {
                                        let newParts = [...parts];
                                        newParts[index].annotationAnswer = e.target.value;
                                        updateStepData(selectedStep, {parts: newParts})
                                    }}
                                                        placeholder='Answer' style={{ width: 'calc(100% - 20px)', height: 30 }}
                                />
                            )
                        }
                        return (
                            <SuggestionWindow key={'suggestion_' + index}
                                    onMouseEnter={() => { setHoveredHighlight(part.index); }}
                                    onMouseLeave={() => { setHoveredHighlight(null); }}
                                >
                                <SuggestionText>{part.question}</SuggestionText>
                                <div style={{display: 'flex', flexDirection: 'column', gap: 5, alignItems: 'center'}}>
                                    {inputComponents}
                                </div>
                            </SuggestionWindow>
                        )
                    })
                }
            </SuggestionsContainer>
                            </ProcessedCommentContainer>
                            
                        </SideContentWrapper>
                    </RightSide>
                </MainContainer>
                {/* Copy button */}
                <div style={{ position: 'absolute', right: '22px', bottom: '55px', transform: 'translateY(-50%)'}}>
                    <CustomButton
                        onClick={handleCopy}
                        text={isCopied ? "Copied!" : "Copy"}
                        iconName="FaCopy"

                    />
                </div> 
            </Wrapper>
             {/* Delete All Notes button */}
             <div style={{ position: 'absolute', right: '22px', bottom: '15px'}}>
                    <CustomButton
                        onClick={handleDeleteAllNotes}
                        text="Restart"
                        iconName="FaTrash"
                    />
                </div>
                {editingTranscript && (
    <TranscriptEditor
        transcript={editingTranscript}
        onSave={(savedTranscript) => {
            handleSaveTranscript(savedTranscript);
            setEditingTranscript(null);
        }}
        onClose={() => setEditingTranscript(null)}
    />
)}
        </Container>
    );
}

export default Main;
