import { TaskDiv, TaskInnerDiv } from "./index.style";

function Task(props) {

    // Prop determining whether this task is already completed or not
    //const [isCompleted, setIsCompleted] = useState(false);

    return (
        <TaskDiv>
            <TaskInnerDiv isCompleted={props.isCompleted}>
                {props.name}
            </TaskInnerDiv>
        </TaskDiv>
    );
}

export default Task;